import {ICircuitData, IData} from "../types";

const rnd = (min: number = 0, max: number = 1): number  => {
    return min + Math.random() * Math.abs(max - min);
};

const genCircuitData = (): ICircuitData => {
    const fba11cp001: number = rnd(.5, 1);
    const fba11cp002: number = rnd(.5, 1);
    const fba11cp003: number = rnd(.5, 1);
    const fba11cf001: number = rnd(0, 50);
    const fba11ct001: number = rnd(90, 100);

    return {
        "indicatorOnline": true,
        "flushingUpDown": false,
        "flushingDownUp": false,
        "sampleInfusionIndicatorLowPressure": false,
        "sampleInfusionIndicatorHighPressure": false,

        "cycleTime.hours": 0,
        "cycleTime.minutes": 0,
        "cycleTime.seconds": 0,
        "operationTime.hours": 0,
        "operationTime.minutes": 0,
        "operationTime.seconds": 0,

        "workModeButton.enabled": true,
        "workModeButton.mode": true,
        "workModeButton.manual": false,

        "startButton.enabled": true,
        "startButton.mode": false,

        "pauseButton.enabled": false,
        "pauseButton.mode": false,

        "regularModeButton.enabled": true,
        "regularModeButton.mode": true,

        "cycledModeButton.enabled": true,
        "cycledModeButton.mode": false,

        "combinedModeButton.enabled": true,
        "combinedModeButton.mode": false,

        "originalButton.enabled": true,
        "originalButton.mode": false,

        //"accidentButton.command": false,
        "accidentButton.mode": false,

        "soundNotificationButton.enabled": true,
        "soundNotificationButton.mode": false,

        "circuitSealedButton.green": true,
        "circuitSealedButton.red": false,
        "circuitSealedButton.yellow": false,

        "accidentIndicator.reset": true,
        "buttonPageAdmin.enabled": true,
        "buttonLogout": true,
        "buttonLogout.enabled": true,
        "contourNumberColor": true,
        "TVS_view": true,
        "inputTVSNumber": 123546,
        "inputProgramNumber": 123546,
        "indicatorSimulatorOperation": true,

        fba11cp001,
        fba11cp002,
        fba11cp003,
        fba11cf001,
        fba11ct001,

        "aa001.green": true,
        "aa001.yellow": false,
        "aa001.red": false,

        "aa002.green": true,
        "aa002.yellow": false,
        "aa002.red": false,

        "aa003.green": true,
        "aa003.yellow": false,
        "aa003.red": false,

        "aa004.green": true,
        "aa004.yellow": false,
        "aa004.red": false,

        "aa005.green": true,
        "aa005.yellow": false,
        "aa005.red": false,

        "aa006.green": true,
        "aa006.yellow": false,
        "aa006.red": false,

        "aa501.green": true,
        "aa501.yellow": false,
        "aa501.red": false,

        "aa505.green": true,
        "aa505.yellow": false,
        "aa505.red": false,

        //ap001: 0,
        ct001: fba11ct001,
        cf001: fba11cf001,
        ct002: fba11ct001,
        cg001: 15,
        cp001: fba11cp001,
        cp002: fba11cp002,
        indicatorLogging: true,
        indicatorSampleReady: true,
        cycleCount: 15,
        messageBlack: ``,
        messageRed: ``,
        "inputUserName": "4343535", // new
        "inputUserPassword": "324324234" // new
    }
}

export const genData = (): IData => {
    return {
        circuit_1: genCircuitData(),
        circuit_2: genCircuitData()
    };
};

export const data: IData = genData();

const isScenarioPlay: boolean[] = [
    false,
    false
];

const circuitScenarioIndexes: number[] = [0, 0];

const getScenarioPlay = (index: number): boolean => {
    return isScenarioPlay[index];
};

const getScenarioPart = async (modifier: () => void, delay: number): Promise<void> => {
    return new Promise((): void => {
        window.setTimeout(modifier, delay);
    });
};

const setInScenarioPlay = (index: number, data: ICircuitData): void => {
    console.log(`air in scenario, circuit ${index}`);
    isScenarioPlay[index] = true;

    Promise.all([
        getScenarioPart((): void => {
            data["aa006.green"] = false;
            data["aa006.yellow"] = true;
        }, 0),
        getScenarioPart((): void => {
            data["aa006.green"] = true;
            data["aa006.yellow"] = false;
            isScenarioPlay[index] = false;
        }, 6000)
    ])
        .catch(null);
}

const setOutScenarioPlay = (index: number, data: ICircuitData): void => {
    console.log(`air out scenario, circuit ${index}`);
    isScenarioPlay[index] = true;

    Promise.all([
        getScenarioPart((): void => {
            data["aa501.green"] = false;
            data["aa501.yellow"] = true;
        }, 0),
        getScenarioPart((): void => {
            data["aa501.green"] = true;
            data["aa501.yellow"] = false;
            isScenarioPlay[index] = false;
        }, 6000)
    ])
        .catch(null);
}

const setRightScenarioPlay = (index: number, data: ICircuitData): void => {
    console.log(`water right scenario, circuit ${index}`);
    isScenarioPlay[index] = true;

    Promise.all([
        getScenarioPart((): void => {
            data["aa002.green"] = false;
            data["aa003.green"] = false;
            data["aa005.green"] = false;
            data["aa505.green"] = false;
            data["aa002.yellow"] = true;
            data["aa003.yellow"] = true;
            data["aa005.yellow"] = true;
            data["aa505.yellow"] = true;
        }, 0),
        getScenarioPart((): void => {
            data["flushingUpDown"] = true;
        }, 500),
        getScenarioPart((): void => {
            data["aa002.green"] = true;
            data["aa003.green"] = true;
            data["aa005.green"] = true;
            data["aa505.green"] = true;
            data["aa002.yellow"] = false;
            data["aa003.yellow"] = false;
            data["aa005.yellow"] = false;
            data["aa505.yellow"] = false;
            data["flushingUpDown"] = false;
            isScenarioPlay[index] = false;
        }, 6000)
    ])
        .catch(null);
}

const setLeftScenarioPlay = (index: number, data: ICircuitData): void => {
    console.log(`water left scenario, circuit ${index}`);
    isScenarioPlay[index] = true;

    Promise.all([
        getScenarioPart((): void => {
            data["aa001.green"] = false;
            data["aa004.green"] = false;
            data["aa505.green"] = false;
            data["aa001.yellow"] = true;
            data["aa004.yellow"] = true;
            data["aa505.yellow"] = true;
        }, 0),
        getScenarioPart((): void => {
            data["flushingDownUp"] = true;
        }, 500),
        getScenarioPart((): void => {
            data["aa001.green"] = true;
            data["aa004.green"] = true;
            data["aa505.green"] = true;
            data["aa001.yellow"] = false;
            data["aa004.yellow"] = false;
            data["aa505.yellow"] = false;
            data["flushingDownUp"] = false;
            isScenarioPlay[index] = false;
        }, 6000)
    ])
        .catch(null);
}

const setCycledScenarioPlay = (index: number, data: ICircuitData): void => {
    console.log(`water cycled scenario, circuit ${index}`);
    isScenarioPlay[index] = true;

    Promise.all([
        getScenarioPart((): void => {
            data["aa004.green"] = false;
            data["aa005.yellow"] = true;
        }, 0),
        getScenarioPart((): void => {
            data['sampleInfusionIndicatorLowPressure'] = true;
        }, 500),
        getScenarioPart((): void => {
            data["aa004.green"] = true;
            data["aa005.yellow"] = false;
            data['sampleInfusionIndicatorLowPressure'] = false;
            isScenarioPlay[index] = false;
        }, 6000)
    ])
        .catch(null);
};

const modValue = (value: number, max: number = Infinity): number => {
    if (rnd() < 0.85) return value;
    return Math.max(Math.min(value + value * rnd(-0.15, 0.15), max), 0);
};

const modCircuitData = (index: number, data: ICircuitData): ICircuitData => {
    if (rnd() > 0.66 && !getScenarioPlay(index)) {
        const scenarios: (() => void)[] = [
            () => setInScenarioPlay(index, data),
            () => setOutScenarioPlay(index, data),
            () => setRightScenarioPlay(index, data),
            () => setLeftScenarioPlay(index, data),
            () => setCycledScenarioPlay(index, data)
        ];

        const scenarioIndex: number = circuitScenarioIndexes[index];
        const scenario = scenarios[scenarioIndex]; //scenarios[Math.round(rnd(0, scenarios.length - 1))];
        circuitScenarioIndexes[index] = scenarioIndex === scenarios.length - 1 ? 0 : scenarioIndex + 1;
        !!scenario && scenario();
    }

    const nextData: IData = Object.keys(data).reduce((acc: Record<string, any>, key: string): Record<string, any> => {
        if (new RegExp(/^cp|cf|cg/i).test(key)) {
            return {
                ...acc,
                // @ts-ignore
                [key]: modValue(data[key])
            };
        }

        if (new RegExp(/^ct/i).test(key)) {
            return {
                ...acc,
                // @ts-ignore
                [key]: modValue(data[key], 100)
            };
        }

        return acc;
    }, {}) as IData;

    return {
        ...data,
        ...nextData
    };
}

export const modData = (): IData => {
    const circuitData1: ICircuitData = modCircuitData(0, data.circuit_1);
    const circuitData2: ICircuitData = modCircuitData(1, data.circuit_2);

    return {
        circuit_1: circuitData1,
        circuit_2: circuitData2
    };
};