import {makeAutoObservable, runInAction} from "mobx";
import {genData, modData} from "../helpers/mockData";
import {
    IRequestArgs,
    IData,
    ISettings,
    ICircuitData,
    ICircuitCanvasData,
    TCircuitIndex,
    TLog
} from "../types";

const __IS_MOCK_DATA__: boolean = false;
export const __API_URL__: string = `${(window as any).__API_HOST__}/backend/api/v1`;
const __FIRST_CIRCUIT_URL__: string = `${__API_URL__}/first-contour`;
const __SECOND_CIRCUIT_URL__: string = `${__API_URL__}/second-contour`;

const settingsParamsMap = {
    "CompensatorLiquidVolume": "compensatorLiquidVolume",
    "CircuitPressure": "circuitPressure",
    "AllowablePressureChangeInTesting": "allowablePressureChangeInTesting",
    "AllowableChangePistonPositionInTesting": "allowableChangePistonPositionInTesting",
    "PressureValuePulseValveControl": "pressureValuePulseValveControl",
    "AccuracyPressureControlPressureCompensator": "accuracyPressureControlPressureCompensator",
    "AdminName": "adminName",
    "UserName1": "userName1",
    "UserName2": "userName2",
    "UserName3": "userName3",
    "UserName4": "userName4",
    "UserName5": "userName5",
    "UserName6": "userName6",
    "UserName7": "userName7",
    "UserName8": "userName8",
    "AdminPassword": "adminPassword",
    "UserPassword1": "userPassword1",
    "UserPassword2": "userPassword2",
    "UserPassword3": "userPassword3",
    "UserPassword4": "userPassword4",
    "UserPassword5": "userPassword5",
    "UserPassword6": "userPassword6",
    "UserPassword7": "userPassword7",
    "UserPassword8": "userPassword8",
    "CycleTestTime": "cycleTestTime",
    "AA006_ValveOnDurationInterrupt": "aa006_ValveOnDurationInterrupt",
    "AA006_ValveOffDurationInterrupt": "aa006_ValveOffDurationInterrupt",
    "AA501_ValveOnDurationInterrupt": "aa501_ValveOnDurationInterrupt",
    "AA501_ValveOffDurationInterrupt": "aa501_ValveOffDurationInterrupt",
    "SetPistonPositionUpDown": "setPistonPositionUpDown",
    "AccuracyOfPistonPositionSettingUpDown": "accuracyOfPistonPositionSettingUpDown",
    "DistanceToSpecifiedPistonPositionPulseSwitchingUpDown": "distanceToSpecifiedPistonPositionPulseSwitchingUpDown",
    "PauseDurationForIntermittentControlUpDown": "pauseDurationForIntermittentControlUpDown",
    "PulseDurationForIntermittentControlUpDown": "pulseDurationForIntermittentControlUpDown",
    "ValveActivationAA505_DelayTimeUpDown": "valveActivationAA505_DelayTimeUpDown",
    "WashingTimeUpDown": "washingTimeUpDown",
    "SetPistonPositionDownUp": "setPistonPositionDownUp",
    "AccuracyOfPistonPositionSettingDownUp": "accuracyOfPistonPositionSettingDownUp",
    "DistanceToSpecifiedPistonPositionPulseSwitchingDownUp": "distanceToSpecifiedPistonPositionPulseSwitchingDownUp",
    "PauseDurationForIntermittentControlDownUp": "pauseDurationForIntermittentControlDownUp",
    "PulseDurationForIntermittentControlDownUp": "pulseDurationForIntermittentControlDownUp",
    "ValveActivationAA004_DelayTimeDownUp": "valveActivationAA004_DelayTimeDownUp",
    "WashingTimeDownUp": "washingTimeDownUp",
    "AA005_ValveClosingDelay": "aa005_ValveClosingDelay",
    "AA505_ValveClosingDelay": "aa505_ValveClosingDelay",
    "DelayStartingCircuitTest": "delayStartingCircuitTest",
    "SimulatorButton": "simulatorButton",
    "SampleInfusionPressureHP": "sampleInfusionPressureHP",
    "PressureControlDeltaHP": "pressureControlDeltaHP",
    "DistanceToSpecifiedPistonPositionPulseSwitchingHP": "distanceToSpecifiedPistonPositionPulseSwitchingHP",
    "DurationStateON_AA501_ValveDuringIntermittentControlHP": "durationStateON_AA501_ValveDuringIntermittentControlHP",
    "DurationStateOFF_AA501_ValveDuringIntermittentControlHP": "durationStateOFF_AA501_ValveDuringIntermittentControlHP",
    "SampleInfusionTimeHP": "sampleInfusionTimeHP",
    "MinimumPumpFlowHP": "minimumPumpFlowHP",
    "TurningOnPauseDelayOnNoFlowPumpHP": "turningOnPauseDelayOnNoFlowPumpHP",
    "SampleInfusionPressureLP": "sampleInfusionPressureLP",
    "PressureControlDeltaLP": "pressureControlDeltaLP",
    "DistanceToSpecifiedPistonPositionPulseSwitchingLP": "distanceToSpecifiedPistonPositionPulseSwitchingLP",
    "DurationStateON_AA505_ValveDuringIntermittentControlLP": "durationStateON_AA505_ValveDuringIntermittentControlLP",
    "DurationStateOFF_AA505_ValveDuringIntermittentControlLP": "durationStateOFF_AA505_ValveDuringIntermittentControlLP",
    "SampleInfusionTimeLP": "sampleInfusionTimeLP",
    "MinimumPumpFlowLP": "minimumPumpFlowLP",
    "TurningOnPauseDelayOnNoFlowPumpLP": "turningOnPauseDelayOnNoFlowPumpLP",
    "DurationSampleInfusionLowPressureCyclingMode": "durationSampleInfusionLowPressureCyclingMode",
    "DurationSampleInfusionAndTimeUntilReadySignalNumber7": "durationSampleInfusionAndTimeUntilReadySignalNumber7",
    "TimeToReadySampleNumber1": "timeToReadySampleNumber1",
    "TimeToReadySampleNumber2": "timeToReadySampleNumber2",
    "TimeToReadySampleNumber3": "timeToReadySampleNumber3",
    "TimeToReadySampleNumber4": "timeToReadySampleNumber4",
    "TimeToReadySampleNumber5": "timeToReadySampleNumber5",
    "TimeToReadySampleNumber6": "timeToReadySampleNumber6",
    "BlockingSettings1": "blockingSettings1",
    "BlockingSettings2": "blockingSettings2",
    "BlockingSettings3": "blockingSettings3",
    "BlockingSettings4": "blockingSettings4",
    "BlockingSettings5": "blockingSettings5",
    "BlockingSettings6": "blockingSettings6",
    "BlockingSettings7": "blockingSettings7",
    "BlockingSettings8": "blockingSettings8",
    "BlockingSettings9": "blockingSettings9",
    "BlockingSettings10": "blockingSettings10",
    "BlockingSettings11": "blockingSettings11",
    "BlockingSettings12": "blockingSettings12",
    "BlockingSettings13": "blockingSettings13",
    "BlockingSettings14": "blockingSettings14",
    "BlockingSettings15": "blockingSettings15",
    "BlockingSettings16": "blockingSettings16",
    "BlockingSettings17": "blockingSettings17",
    "BlockingSettings18": "blockingSettings18",
    "BlockingSettings19": "blockingSettings19",
    "BlockingSettings20": "blockingSettings20",
    "GeneralSettings1": "generalSettings1",
    "GeneralSettings2": "generalSettings2",
    "GeneralSettings3": "generalSettings3",
    "GeneralSettings4": "generalSettings4",
    "GeneralSettings5": "generalSettings5",
    "GeneralSettings6": "generalSettings6",
    "GeneralSettings7": "generalSettings7",
    "GeneralSettings8": "generalSettings8",
    "GeneralSettings9": "generalSettings9",
    "GeneralSettings10": "generalSettings10",
    "GeneralSettings11": "generalSettings11",
    "GeneralSettings12": "generalSettings12",
    "GeneralSettings13": "generalSettings13",
    "GeneralSettings14": "generalSettings14",
    "GeneralSettings15": "generalSettings15",
    "GeneralSettings16": "generalSettings16",
    "GeneralSettings17": "generalSettings17",
    "GeneralSettings18": "generalSettings18",
    "GeneralSettings19": "generalSettings19",
    "GeneralSettings20": "generalSettings20",
    "OffsetForCircuitContourInput": "offsetForCircuitContourInput",
    "OffsetWaterTemperatureContourInput": "offsetWaterTemperatureContourInput",
    "OffsetWaterPressureContourInput": "offsetWaterPressureContourInput",
    "OffsetDistillatePressureContourInput": "offsetDistillatePressureContourInput",
    "OffsetAirPressureContourInput": "offsetAirPressureContourInput",
    "OffsetAirPressureCompensator": "offsetAirPressureCompensator",
    "OffsetPumpDischargeWaterPressure": "offsetPumpDischargeWaterPressure",
    "OffsetWaterTemperatureT1_Penal": "offsetWaterTemperatureT1_Penal",
    "OffsetWaterTemperatureT2_Penal": "offsetWaterTemperatureT2_Penal",
    "OffsetWaterFlowPumpHead": "offsetWaterFlowPumpHead",
    "OffsetVolumeWaterCompensator": "offsetVolumeWaterCompensator",
    "MaximumScaleValueWaterFlowContourInput": "maximumScaleValueWaterFlowContourInput",
    "MaximumScaleValueWaterTemperatureContourInput": "maximumScaleValueWaterTemperatureContourInput",
    "MaximumScaleValueWaterPressureContourInput": "maximumScaleValueWaterPressureContourInput",
    "MaximumScaleValueWaterDistillateContourInput": "maximumScaleValueWaterDistillateContourInput",
    "MaximumScaleValueAirPressureContourInput": "maximumScaleValueAirPressureContourInput",
    "MaximumScaleValueAirPressureCompensator": "maximumScaleValueAirPressureCompensator",
    "MaximumScaleValuePumpDischargeWaterPressure": "maximumScaleValuePumpDischargeWaterPressure",
    "MaximumScaleValueWaterTemperatureT1_Penal": "maximumScaleValueWaterTemperatureT1_Penal",
    "MaximumScaleValueWaterTemperatureT2_Penal": "maximumScaleValueWaterTemperatureT2_Penal",
    "MaximumScaleValueWaterFlowPumpHead": "maximumScaleValueWaterFlowPumpHead",
    "MaximumScaleValueVolumeWaterCompensator": "maximumScaleValueVolumeWaterCompensator",
    "FormulaMaxValue": "formulaMaxValue",
    "FormulaMinValue": "formulaMinValue",
    "FormulaScale": "formulaScale"
};

class AppStore {
    public data: IData;
    public settings: ISettings[] = [];
    private messages: Record<string, string>[] = [];
    private buffers: number[][][] = [];
    private logs: TLog[] = [];
    public notification: string | null = null;
    public isFetched: boolean = false;
    public isSettingsFetched: boolean = false;
    public isSettingsFetching: boolean = false;
    public isSettingsSaveModalOpen: boolean = false;
    public isSettingsRestoreModalOpen: boolean = false;
    private settingsDiffs: Record<string, any> = {};
    public settingsTime: number = 0;

    constructor() {
        makeAutoObservable(this);
        this.data = __IS_MOCK_DATA__ ? genData() : this.mapData({}, {});
    }

    private requestApi = async ({url, method = `POST`, body = null}: IRequestArgs): Promise<any> => {
        const headers = {
            'Content-type': 'application/json'
        };

        const res: Response | void = await fetch(url, {method, headers, body})
            .catch(() => {
                runInAction(() => {
                    this.notify(`Произошла ошибка`);
                });
            });

        try {
            const data = await res?.json();
            if (!res?.ok) {
                this.notify(data.description ?? `Произошла ошибка`);
            }
            return data;
        } catch {
            return res;
        }
    }

    public setSettingsSaveModalOpen = (isOpen: boolean): void => {
        this.isSettingsSaveModalOpen = isOpen;
    }

    public setSettingsRestoreModalOpen = (isOpen: boolean): void => {
        this.isSettingsRestoreModalOpen = isOpen;
    }

    public setData = (data: IData): void => {
        this.data = data;
        this.cacheData();
        this.logMessages();
    }

    public modifyData = () => {
        if (!this.data) return;
        this.setData(modData());
    }

    public isCircuitAuthorized = (circuitIndex: TCircuitIndex): boolean => {
        const circuitData: ICircuitData = this.getCircuitData(circuitIndex);
        return circuitData[`originalButton.enabled`] || circuitData[`buttonPageAdmin.enabled`];
    }

    public isAdminAccess = (circuitIndex: TCircuitIndex): boolean => {
        const circuitData: ICircuitData = this.getCircuitData(circuitIndex);
        return circuitData[`buttonPageAdmin.enabled`];
    }

    public getServerTime = (): number => {
        return new Date().valueOf();
    }

    public mapData = (circuitFirstData: Record<string, any>, circuitSecondData: Record<string, any>): IData => {
        return {
            circuit_1: this.mapCircuitData(1, circuitFirstData),
            circuit_2: this.mapCircuitData(2, circuitSecondData)
        }
    }

    private mapCircuitData = (index: TCircuitIndex, data: Record<string, any> = {}): ICircuitData => {
        return {
            "indicatorOnline": data[`IndicatorOnline`] === `ON`,
            "flushingUpDown": data[`FlushingIndicatorUpDown`],
            "flushingDownUp": data[`FlushingIndicatorDownUp`],
            "sampleInfusionIndicatorLowPressure": data['SampleInfusionIndicatorLowPressure'],
            "sampleInfusionIndicatorHighPressure": data['SampleInfusionIndicatorHighPressure'],
            "cycleTime.hours": data[`CycleTimeHours`],
            "cycleTime.minutes": data[`CycleTimeMinutes`],
            "cycleTime.seconds": data[`CycleTimeSeconds`],
            "operationTime.hours": data[`OperationTimeHours`],
            "operationTime.minutes": data[`OperationTimeMinutes`],
            "operationTime.seconds": data[`OperationTimeSeconds`],
            "workModeButton.enabled": data[`ButtonAutoManualSwitch.enabled`],
            "workModeButton.mode": data[`ButtonAutoManualSwitch.orange-green`],
            "workModeButton.manual": data[`ButtonAutoManualSwitch.red`],
            "startButton.enabled": data[`ButtonStart.enabled`],
            "startButton.mode": data[`ButtonStart.red-green`],
            "pauseButton.enabled": data[`Pause.enabled`],
            "pauseButton.mode": data[`Pause.orange-green`],
            "regularModeButton.enabled": data[`ButtonSwitch.enabled`],
            "regularModeButton.mode": data[`ButtonSwitchRegularMode.green`],
            "cycledModeButton.enabled": data[`ButtonSwitch.enabled`],
            "cycledModeButton.mode": data[`ButtonSwitchCyclingMode.green`],
            "combinedModeButton.enabled": data[`ButtonSwitch.enabled`],
            "combinedModeButton.mode": data[`ButtonSwitchCombinedMode.green`],
            "originalButton.enabled": data[`ButtonPageAdmin.enabled`],
            "originalButton.mode": data[`ButtonOriginal.green`],
            "accidentButton.mode": data[`AccidentIndicator.red`],
            "accidentIndicator.reset": data[`AccidentIndicator.reset`],
            "soundNotificationButton.enabled": data[`ButtonAlarmUnmute`],
            "soundNotificationButton.mode": data[`ButtonAlarmUnmute.red`],
            "circuitSealedButton.green": data[`ButtonSealedCircuit.green`],
            "circuitSealedButton.red": data[`ButtonSealedCircuit.red`],
            "circuitSealedButton.yellow": data[`ButtonSealedCircuit.orange`],
            "buttonPageAdmin.enabled": data[`ButtonPageAdmin.enabled`],
            "buttonLogout": data["ButtonLogout"],
            "buttonLogout.enabled": data["ButtonLogout.enabled"],
            "contourNumberColor": data["ContourNumberColor"],
            "TVS_view": data["TVS_view"],
            "inputTVSNumber": data["InputTVSNumber"],
            "inputProgramNumber": data["InputProgramNumber"],
            "indicatorSimulatorOperation": data["IndicatorSimulatorOperation"],
            "fba11cp001": data[`FBA${index}1CP001`],
            "fba11cp002": data[`FBA${index}1CP002`],
            "fba11cp003": data[`FBA${index}1CP003`],
            "fba11cf001": data[`FBA${index}1CF001`],
            "fba11ct001": data[`FBA${index}1CT001`],
            "aa001.green": data[`FBA${index}0AA001.green`],
            "aa001.yellow": data[`FBA${index}0AA001.yellow`],
            "aa001.red": data[`FBA${index}0AA001.red`],
            "aa002.green": data[`FBA${index}0AA002.green`],
            "aa002.yellow": data[`FBA${index}0AA002.yellow`],
            "aa002.red": data[`FBA${index}0AA002.red`],
            "aa003.green": data[`FBA${index}0AA003.green`],
            "aa003.yellow": data[`FBA${index}0AA003.yellow`],
            "aa003.red": data[`FBA${index}0AA003.red`],
            "aa004.green": data[`FBA${index}0AA004.green`],
            "aa004.yellow": data[`FBA${index}0AA004.yellow`],
            "aa004.red": data[`FBA${index}0AA004.red`],
            "aa005.green": data[`FBA${index}0AA005.green`],
            "aa005.yellow": data[`FBA${index}0AA005.yellow`],
            "aa005.red": data[`FBA${index}0AA005.red`],
            "aa006.green": data[`FBA${index}0AA006.green`],
            "aa006.yellow": data[`FBA${index}0AA006.yellow`],
            "aa006.red": data[`FBA${index}0AA006.red`],
            "aa501.green": data[`FBA${index}0AA501.green`],
            "aa501.yellow": data[`FBA${index}0AA501.yellow`],
            "aa501.red": data[`FBA${index}0AA501.red`],
            "aa505.green": data[`FBA${index}0AA505.green`],
            "aa505.yellow": data[`FBA${index}0AA505.yellow`],
            "aa505.red": data[`FBA${index}0AA505.red`],
            "ct001": data[`FBA${index}0CT001`],
            "ct002": data[`FBA${index}0CT002`],
            "cg001": data[`FBA${index}0CG001`],
            "cf001": data[`FBA${index}0CF001`],
            "cp001": data[`FBA${index}0CP001`],
            "cp002": data[`FBA${index}0CP002`],
            "indicatorLogging": data[`IndicatorLogging`],
            "indicatorSampleReady": data[`IndicatorSampleReady`],
            "cycleCount": data["CycleCount"],
            "messageBlack": data["MessageBlack"],
            "messageRed": data["MessageRed"],
            "inputUserName": data["InputUserName"],
            "inputUserPassword": data["InputUserPassword"]
        }
    }

    private logMessages = (): void => {
        const logCircuitMessages = (index: TCircuitIndex): void => {
            const messageBlackKey: string = this.data[`circuit_${index}`]["messageBlack"];
            const messageRedKey: string = this.data[`circuit_${index}`]["messageRed"];

            if (!!messageBlackKey) {
                const message = this.messages?.find((m: Record<string, string>) => m.number === `${messageBlackKey}`);
                if (!this.logs[index]) this.logs[index] = [];
                if (!!message) this.logs[index].push([new Date().valueOf(), message.value, `message`]);
            }

            if (!!messageRedKey) {
                const message = this.messages?.find((m: Record<string, string>) => m.number === `${messageRedKey}`);
                if (!this.logs[index]) this.logs[index] = [];
                if (!!message) this.logs[index].push([new Date().valueOf(), message.value, `warn`]);
            }
        }
        logCircuitMessages(1);
        logCircuitMessages(2);
    }

    public getLog = (circuitIndex: TCircuitIndex): TLog => {
        return this.logs[circuitIndex] ?? [];
    }

    private notify = (message: string): void => {
        this.clearNotification();
        this.notification = message;
    }

    public clearNotification = (): void => {
        this.notification = null;
    }

    public getCircuitBuffer = (index: TCircuitIndex): number[][] => {
        return this.buffers[index];
    }

    private cacheData = (): void => {
        const cacheCircuitData = (index: TCircuitIndex): void => {
            const canvasData: ICircuitCanvasData = this.getCircuitCanvasData(index);
            this.buffers[index] = [...(this.buffers[index] ?? []), [this.getServerTime(), canvasData.cg001, canvasData.cp001]];
        }
        cacheCircuitData(1);
        cacheCircuitData(2);
    }

    public getCircuitCanvasData = (circuitIndex: TCircuitIndex): ICircuitCanvasData => ({
        cg001: this.data?.[`circuit_${circuitIndex}`]?.cg001,
        cp001: this.data?.[`circuit_${circuitIndex}`]?.cp001
    });

    public getCircuitData = (circuitIndex: TCircuitIndex): ICircuitData => {
        return this.data?.[`circuit_${circuitIndex}`];
    }

    private getCircuitUrl = (circuitIndex: number): string => {
        return [
            __FIRST_CIRCUIT_URL__,
            __SECOND_CIRCUIT_URL__
        ][circuitIndex - 1];
    }

    public login = async (circuitIndex: TCircuitIndex, username: string, password: string): Promise<void> => {
        const loginRes = await this.requestApi({
            url: `${this.getCircuitUrl(circuitIndex)}/parameters`,
            body: JSON.stringify({
                InputUserName: username,
                InputUserPassword: password
            })
        });

        runInAction(() => {
            if (loginRes?.status === 201) {
                this.fetchCircuitSettings(circuitIndex);
            }
        });
    }

    public logout = async (circuitIndex: number) => {
        await this.buttonPress(`ButtonLogout`, circuitIndex);
    }

    private fetchCircuitData = async (circuitIndex: number): Promise<IData> => {
        return this.requestApi({
            url: `${this.getCircuitUrl(circuitIndex)}/parameters`,
            method: `GET`
        });
    }

    public fetchData = async (): Promise<void> => {
        if (__IS_MOCK_DATA__) {
            this.modifyData();
        } else {
            const circuitFirstData: IData = await this.fetchCircuitData(1);
            const circuitSecondData: IData = await this.fetchCircuitData(2);
            runInAction(() => {
                this.setData(this.mapData(circuitFirstData, circuitSecondData));
            });
        }

        runInAction(() => {
            this.isFetched = true;
        });
    }

    public mutateRegister = async (id: string, value: any, circuitIndex: number) => {
        await this.requestApi({
            url: `${this.getCircuitUrl(circuitIndex)}/parameters`,
            body: JSON.stringify({[id]: value})
        });
    }

    public buttonPress = async (id: string, circuitIndex: number) => {
        await this.requestApi({
            url: `${this.getCircuitUrl(circuitIndex)}/parameters/button`,
            body: id
        });
    }

    public settingsButtonPress = async (id: string, circuitIndex: TCircuitIndex) => {
        await this.requestApi({
            url: `${this.getCircuitUrl(circuitIndex)}/settings/button`,
            body: id
        });
        await this.fetchCircuitSettings(circuitIndex);
    }

    public getCircuitSettings = (circuitIndex: number): ISettings => {
        return this.settings[circuitIndex] ?? {};
    }

    public fetchCircuitSettings = async (circuitIndex: TCircuitIndex) => {
        if (!this.isCircuitAuthorized(circuitIndex)) return;
        const settingsData: ISettings = await this.requestApi({
            url: `${this.getCircuitUrl(circuitIndex)}/settings`,
            method: `GET`
        });
        runInAction(() => {
            this.settings[circuitIndex] = settingsData;
        });
        this.settingsTime = new Date().valueOf();
    }

    public fetchSettings = async () => {
        await this.fetchCircuitSettings(1);
        await this.fetchCircuitSettings(2);
        runInAction(() => {
            this.isSettingsFetched = true;
        });
    }

    public updateCircuitSettings = (key: string, value: string | number | boolean, circuitIndex: number): void => {
        const settings: ISettings = this.getCircuitSettings(circuitIndex);

        // @ts-ignore
        const currentValue = settings[key];
        if (`${value}` === `${currentValue}`) {
            delete this.settingsDiffs[key];
        } else {
            this.settingsDiffs[key] = value;
        }
        // @ts-ignore
        this.settings[circuitIndex][key] = value;
    }

    public getCircuitSettingsRequestBody = (circuitIndex: TCircuitIndex) => {
        const settings: ISettings = this.getCircuitSettings(circuitIndex);
        const body = {};
        const actualSettings = {...settings, ...this.settingsDiffs};

        for (const key in actualSettings) {
            // @ts-ignore
            if (!!settingsParamsMap[key] && !!actualSettings[key]) {
                // @ts-ignore
                body[settingsParamsMap[key]] = actualSettings[key];
            }
        }
        return body;
    }

    public applyCircuitSettings = async (circuitIndex: TCircuitIndex) => {
        if (!this.isAdminAccess(circuitIndex)) return;
        const diffs = {};
        for (const key in this.settingsDiffs) {
            // @ts-ignore
            diffs[settingsParamsMap[key]] = this.settingsDiffs[key];
        }
        const res = await this.requestApi({
            url: `${this.getCircuitUrl(circuitIndex)}/settings`,
            body: JSON.stringify(diffs)
        });

        if (res.status === 200) {
            runInAction(() => {
                this.settingsDiffs = {};
            });
        }
    }

    public saveCircuitSettings = async (circuitIndex: TCircuitIndex) => {
        if (!this.isAdminAccess(circuitIndex)) return;

        runInAction(() => {
            this.isSettingsFetching = true;
        });

        await this.requestApi({
            url: `${this.getCircuitUrl(circuitIndex)}/db_settings`,
            body: JSON.stringify(this.getCircuitSettingsRequestBody(circuitIndex))
        });

        runInAction(() => {
            this.isSettingsFetching = false;
        });
    }

    public restoreCircuitSettings = async (circuitIndex: TCircuitIndex) => {
        if (!this.isAdminAccess(circuitIndex)) return;

        runInAction(() => {
            this.isSettingsFetching = true;
        });

        await this.requestApi({
            url: `${this.getCircuitUrl(circuitIndex)}/recovery`
        });
        await this.fetchCircuitSettings(circuitIndex);

        runInAction(() => {
            this.isSettingsFetching = false;
        });
    }

    public fetchMessages = async () => {
        const messages = await this.requestApi({
            url: `${__API_URL__}/messages`,
            method: `GET`
        });

        runInAction(() => {
            this.messages = messages;
        });
    }

    public uploadMessages = async (file: File) => {
        await this.requestApi({
            url: `${__API_URL__}/messages`,
            body: await file.text()
        });
    }
}

const appStore = new AppStore();
export default appStore;