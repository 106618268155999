import React, {FC, lazy, Suspense, useCallback, useEffect} from "react";
import {observer} from "mobx-react-lite";
import {Route, Routes} from "react-router-dom";
import {message} from "antd";

import Spinner from "../components/Spinner";
import "./style.css";

import appStore from "../stores/AppStore";

const MainPage = lazy(() => import(`../containers/MainPage`));
const CircuitPage = lazy(() => import(`../containers/CircuitPage`));
const AdminPage1 = lazy(() => import(`../containers/AdminPage1`));
const AdminPage2 = lazy(() => import(`../containers/AdminPage2`));
const AdminPage3 = lazy(() => import(`../containers/AdminPage3`));
const AdminPage4 = lazy(() => import(`../containers/AdminPage4`));
const AdminPage5 = lazy(() => import(`../containers/AdminPage5`));

const App: FC = () => {
    const notification = appStore.notification;

    const [messageApi, contextHolder] = message.useMessage();

    const fetchInitial = useCallback(async () => {
        await appStore.fetchData();
        await appStore.fetchSettings();
        await appStore.fetchMessages();
    }, []);

    useEffect(() => {
        const interval: number = window.setInterval(appStore.fetchData, 1000);
        fetchInitial()
            .catch(null);
        return () => clearInterval(interval);
    }, [fetchInitial]);

    useEffect(() => {
        if (notification) {
            messageApi.open({
                type: `error`,
                content: notification
            })
                .then(appStore.clearNotification);
        }
    }, [messageApi, notification]);

    const isFetched: boolean = appStore.isSettingsFetched && appStore.isFetched;

    return (
        <div className={`app`}>
            {contextHolder}
            {!isFetched && <Spinner />}

            {isFetched && (
                <Suspense fallback={<Spinner/>}>
                    <Routes>
                        <Route path={`/`} element={<MainPage />} />
                        <Route path={`/:circuitIndex`} element={<CircuitPage />} />
                        <Route path={`/:circuitIndex/admin_1`} element={<AdminPage1 />} />
                        <Route path={`/:circuitIndex/admin_2`} element={<AdminPage2 />} />
                        <Route path={`/:circuitIndex/admin_3`} element={<AdminPage3 />} />
                        <Route path={`/:circuitIndex/admin_4`} element={<AdminPage4 />} />
                        <Route path={`/:circuitIndex/admin_5`} element={<AdminPage5 />} />
                    </Routes>
                </Suspense>
            )}
        </div>
    );
}

export default observer(App);
